export const HEADER_HEIGHT = '55px';
export const SIDEBAR_WIDTH = '250px';
export const MAX_WIDTH = '1090px';
export const MAX_WIDTH_WITH_PADDING = '1120px';
export const BOX_SHADOW = '0 1px 2px rgba(0,0,0,0.2)';
export const BOX_SHADOW_MODAL = '0 3px 5px rgba(0, 0, 0, 0.3)';
export const MONO = '"Courier New", "monospace"';
export const NOTIFICATION_WIDTH = 350;
export const NOTIFICATION_HEIGHT = 70;

export function bp(size) {
    const sizes = {
        sm: '480px',
        md: '750px',
        lg: '990px',
        xl: '1200px',
    };

    return `@media (min-width: ${sizes[size]})`;
}

export const COLORS = {
    primary: '#2196f3',
    primaryMedium: '#87c1ee',
    primaryLight: '#E3EBF4',
    success: '#b8e986',
    successAlt: '#18BB74',
    warning: '#f0A611',
    warningLight: '#ffc107',
    danger: '#e01e63',
    highlight: '#FEFAF3',
    grayFont: '#7F8FA4',
    grayLight: '#F2F3F5',
    grayMedium: '#cacaca',
    gray: '#30343C',
    grayDark: '#1B1E23',
    grayExtraDark: '#121418',
    greenDark: '#013220',
    reddishBrown: '#7f2b0a',
};

export const Z = {
    hidden: -1,
    navigation: 100,
    search: 200,
    sidebar: 300,
    modal: 400,
    logoutModal: 500,
    notification: 600,
};

export function lighten(col, amt) {
    let usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}

export function darken(col, amt) {
    return lighten(col, -amt);
}

export function hexToRgb(hex, opacity) {
    let h = hex.replace('#', '');
    h = h.match(new RegExp('(.{' + h.length / 3 + '})', 'g'));

    for (let i = 0; i < h.length; i++)
        h[i] = parseInt(h[i].length === 1 ? h[i] + h[i] : h[i], 16);

    if (typeof opacity !== 'undefined') h.push(opacity);

    return h.join(',');
}
